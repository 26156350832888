<template>
  <b-modal
    :visible="props.show"
    title="Show Problem Automation"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <div class="mb-2">
      <SyncProgress ref="synchProgressBar"
                    queue-key="ai-problem-automation"
                    title="AI Problem Automation "
      />
    </div>
    <table class="table table-hover table-striped">
      <thead>
        <tr>
          <th>Problem</th>
          <th>Question Type</th>
          <th>Answer Type</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="option of options"
            :key="option.value"
        >
          <td>
            <b-form-checkbox
              v-model="option.selected"
              :value="true"
              class="mt-2"
            >
              {{ option.text }}
            </b-form-checkbox>
          </td>
          <td>
            <b-form-select
              v-model="option.ques_type"
              :options="questionAnsType"
            />
          </td>
          <td>
            <b-form-select
              v-model="option.ans_type"
              :options="questionAnsType"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <template #modal-footer>
      <div>
        <b-button variant="outline-secondary"
                  @click="$emit('close')"
        >
          Cancel
        </b-button>
        <b-button
          class="ml-1"
          variant="outline-primary"
          :disabled="isProcessing"
          @click="automateProblem"
        >
          <b-spinner v-show="isProcessing"
                     small
          /> Save
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script setup>
import {
  BModal,
  BFormCheckbox,
  BButton,
  BSpinner,
  BFormSelect,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { getCurrentInstance, ref } from 'vue'
import SyncProgress from '../../image-library/components/SyncProgress.vue'

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  lessonId: {
    type: Number,
    required: true,
  },
})

const questionAnsType = [
  {
    text: 'Text',
    value: 'text',
  },
  {
    text: 'Image',
    value: 'image',
  },
  {
    text: 'Audio',
    value: 'audio',
  },
]

const isProcessing = ref(false)
const options = ref([
  {
    text: 'Drag Position',
    value: 'drag_position',
    selected: false,
    ques_type: 'text',
    ans_type: 'text',
  },
  {
    text: 'Multiple Choice',
    value: 'multiple',
    selected: false,
    ques_type: 'text',
    ans_type: 'text',
  },
  {
    text: 'Matching Problem',
    value: 'matching',
    selected: false,
    ques_type: 'text',
    ans_type: 'text',
  },
  {
    text: 'Prompted Speech',
    value: 'speech',
    selected: false,
    ques_type: 'text',
    ans_type: 'text',
  },
  {
    text: 'Open Ended',
    value: 'open_ended',
    selected: false,
    ques_type: 'text',
    ans_type: 'text',
  },
  {
    text: 'Fill in the blanks',
    value: 'blank',
    selected: false,
    ques_type: 'text',
    ans_type: 'text',
  },
  {
    text: 'FIB Dropdown',
    value: 'fib_dropdown',
    selected: false,
    ques_type: 'text',
    ans_type: 'text',
  },
])
const emits = defineEmits(['close', 'refetch'])
const root = getCurrentInstance().proxy.$root
const synchProgressBar = ref(null)

const automateProblem = async () => {
  // eslint-disable-next-line no-underscore-dangle
  if (synchProgressBar.value?._setupState.isQueueRunning.value) {
    const confirmGenerate = await root.$swal.fire({
      title: 'The Previous problem generation process has not been compted yet. Do you want to force generate the problem?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
      allowOutsideClick: false,
    })
    if (!confirmGenerate.isConfirmed) {
      return
    }
  }

  isProcessing.value = true
  useJwt
    .automateProblem(props.lessonId, {
      selected_options: options.value.filter(i => i.selected),
    })
    .then(response => {
      root.showSuccessMessage(response)
      emits('close')
      emits('refetch')
    })
    .finally(() => {
      isProcessing.value = false
    })
}
</script>
